import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import Header from 'components/Header';
import Container from 'components/ui/Container';
import Button from 'components/ui/Button';
import TitleSectionAbout from 'components/ui/TitleSectionAbout';
import './background.css';
import * as Styled from './styles';
import downloadFile from '../../../assets/downloadables/CV.pdf';

const Banner = ({ title, subtitle, content, linkTo, linkText }) => (
  <Styled.Banner id="aboutBackdrop">
    <Header />
    <Container section>
      <TitleSectionAbout title={title} subtitle={subtitle} />
      <Styled.Content>{content}</Styled.Content>
      <a href={downloadFile}>
        <Button primary center>
          {linkText}
        </Button>
      </a>
    </Container>
  </Styled.Banner>
);

Banner.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  content: PropTypes.any.isRequired,
  linkTo: PropTypes.string.isRequired,
  linkText: PropTypes.string.isRequired
};

export default Banner;
