import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Timeline from 'components/ui/Timeline';
import Container from 'components/ui/Container';
import TitleSection from 'components/ui/TitleSection';
import FormatHtml from 'components/utils/FormatHtml';
import './styles.css';

const Experience = () => {
  const { markdownRemark, allMarkdownRemark } = useStaticQuery(graphql`
    query {
      markdownRemark(frontmatter: { category: { eq: "experiences section" } }) {
        frontmatter {
          title
          subtitle
        }
      }
      allMarkdownRemark(
        filter: { frontmatter: { category: { eq: "experiences" } } }
        sort: { order: DESC, fields: fileAbsolutePath }
      ) {
        edges {
          node {
            id
            html
            frontmatter {
              company
              position
              startDate
              endDate
            }
          }
        }
      }
    }
  `);

  const sectionTitle = markdownRemark.frontmatter;
  const experiences = allMarkdownRemark.edges;

  return (
    <section id="experience">
      <div id="inside">
        <TitleSection title={sectionTitle.title} subtitle={sectionTitle.subtitle} />
        {experiences.map((item) => {
          const {
            id,
            html,
            frontmatter: { company, position, startDate, endDate }
          } = item.node;

          return (
            <Timeline
              key={id}
              title={company}
              subtitle={position}
              content={<FormatHtml content={html} />}
              startDate={startDate}
              endDate={endDate}
            />
          );
        })}
      </div>
    </section>
  );
};

export default Experience;
