import React from 'react';
import AboutHero from 'components/AboutHero';
import Layout from 'components/Layout';
import SEO from 'components/SEO';
import Experience from 'components/Experience';
import Education from 'components/Education';
import Skills from 'components/Skills';
import ConctactInfo from 'components/ContactInfo';

const aboutPage: React.FC = () => (
  <Layout>
    <SEO title="About" />
    <AboutHero />
    <Experience />
    <hr />
    <Education />
    <hr />
    <Skills />
    <ConctactInfo />
    

  </Layout>
);

export default aboutPage;
