import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import './styles.css';
import TitleSection from 'components/ui/TitleSection';
import ProgressBar from 'components/ui/ProgressBar';

import * as Styled from './styles';

const SkillsExtended = () => {
  const { markdownRemark, allMarkdownRemark } = useStaticQuery(graphql`
    query {
      markdownRemark(frontmatter: { category: { eq: "skills extension section" } }) {
        frontmatter {
          title
          subtitle
        }
      }
      allMarkdownRemark(filter: { frontmatter: { category: { eq: "skills-extend" } } }, sort: { fields: fileAbsolutePath }) {
        edges {
          node {
            id
            frontmatter {
              title
              percentage
            }
          }
        }
      }
    }
  `);

  const sectionTitle = markdownRemark.frontmatter;
  const skillsExtend = allMarkdownRemark.edges;

  return (
    <section id="skilled">
      <div id="inside">
        <TitleSection  subtitle={sectionTitle.subtitle} center />
        <Styled.Skills>
          {skillsExtend.map((item) => {
            const {
              id,
              frontmatter: { title, percentage }
            } = item.node;

            return (
              <Styled.Skill key={id}>
                <ProgressBar title={title} percentage={percentage} />
              </Styled.Skill>
            );
          })}
        </Styled.Skills>
      </div>
    </section>
  );
};

export default SkillsExtended;
