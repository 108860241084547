import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';

import AboutBanner from 'components/ui/AboutBanner';

const AboutHero = () => {
  const { markdownRemark } = useStaticQuery(graphql`
    query {
      markdownRemark(frontmatter: { category: { eq: "about hero" } }) {
        frontmatter {
          title
          subtitle
          content
          linkTo
          linkText
        }
      }
    }
  `);

  const aboutBanner = markdownRemark.frontmatter;

  return (
    <AboutBanner
      title={aboutBanner.title}
      subtitle={aboutBanner.subtitle}
      content={aboutBanner.content}
      linkTo={aboutBanner.linkTo}
      linkText={aboutBanner.linkText}
    />
  );
};

AboutHero.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  content: PropTypes.string.isRequired,
  linkTo: PropTypes.string.isRequired,
  linkText: PropTypes.string.isRequired
};

export default AboutHero;
